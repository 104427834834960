// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-element-js": () => import("./../../../src/pages/Element.js" /* webpackChunkName: "component---src-pages-element-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-is-there-value-in-customer-journey-maps-js": () => import("./../../../src/pages/Is-there-value-in-customer-journey-maps.js" /* webpackChunkName: "component---src-pages-is-there-value-in-customer-journey-maps-js" */),
  "component---src-pages-post-1-js": () => import("./../../../src/pages/post-1.js" /* webpackChunkName: "component---src-pages-post-1-js" */),
  "component---src-pages-reimagining-how-we-care-for-our-fur-friends-js": () => import("./../../../src/pages/reimagining-how-we-care-for-our-fur-friends.js" /* webpackChunkName: "component---src-pages-reimagining-how-we-care-for-our-fur-friends-js" */),
  "component---src-pages-state-of-the-sharing-economy-js": () => import("./../../../src/pages/state-of-the-sharing-economy.js" /* webpackChunkName: "component---src-pages-state-of-the-sharing-economy-js" */),
  "component---src-pages-what-is-digital-js": () => import("./../../../src/pages/what-is-digital.js" /* webpackChunkName: "component---src-pages-what-is-digital-js" */),
  "component---src-pages-where-is-the-citizen-in-transformation-js": () => import("./../../../src/pages/where-is-the-citizen-in-transformation.js" /* webpackChunkName: "component---src-pages-where-is-the-citizen-in-transformation-js" */)
}

